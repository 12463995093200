<template>
  <el-dialog
    :title="dialogType == 'add' ? '添加规则' : '编辑规则'"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="680px"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="140px" label-position="left">
      <el-form-item label="用户成交价区间：" required>
        <div style="display:flex">
          <el-form-item label-width="0" prop="lower">
            <el-input v-model="formData.lower" placeholder="请输入起始值" @input="inputNumber($event, formData, 'lower')" />
          </el-form-item>
          <div style="margin:0 10px">~</div>
          <el-form-item label-width="0" prop="upper">
            <el-input v-model="formData.upper" placeholder="请输入结束值" @input="inputNumber($event, formData, 'upper')" />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="下跌比例：" prop="downRatio">
        <el-input v-model="formData.downRatio" placeholder="请输入小于100的数字" @input="inputNumber($event, formData, 'downRatio', 2, 99.99)">
          <template slot="append">%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="最多下跌：" prop="downLimit">
        <el-input v-model="formData.downLimit" maxlength="5" placeholder="请输入上限的金额" @input="inputNumber($event, formData, 'downLimit')">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
    </el-form>
    
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取 消</el-button>
      <el-button size="small" type="primary" @click="confirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import _api from "@/utils/request";
import { inputNumber } from '@/utils/common'
export default {
  name: "TimeQuoteRuleDia",
  props: {
    ruleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const validateRange = (rule, value, callback) => {
      const { field } = rule
      const { lower, upper } = this.formData
      if (!lower || !upper) callback()
      if (Number(lower) >= Number(upper)) {
        if (field == 'lower') callback(new Error('起始值需小于结束值'))
        callback(new Error('结束值需大于起始值'))
      }
      callback()
    }
    const limitLenthVaild = (rule, value, callback) => {
      const number = Number(value)
      if (number < 0 || number >= 100) {
        callback(new Error('请输入小于100的数字'))
      }
      callback()
    }
    return {
      visible: false,
      loading: false,

      dialogType: '',
      formData: {
        lower: '',
        upper: '',
        downRatio: '',
        downLimit: '',
      },
      rules: {
        lower: [
          { required: true, message: '请输入起始值', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        upper: [
          { required: true, message: '请输入结束值', trigger: 'blur' },
          { validator: validateRange, trigger: 'blur' }
        ],
        downRatio: [
          { required: true, message: '请输入下跌比例', trigger: 'blur' },
          { validator: limitLenthVaild, trigger: 'blur' }
        ],
        downLimit: [
          { required: true, message: '请输入上限金额', trigger: 'blur' },
        ],
      }
    };
  },
  methods: {
    open(row) {
      if (row) {
        this.dialogType = 'edit'
      } else {
        this.dialogType = 'add'
      }
      this.formData = row ? JSON.parse(JSON.stringify(row)) : {
        lower: '',
        upper: '',
        downRatio: '',
        downLimit: '',
      }
      this.visible = true
    },
    inputNumber,
    confirm() {
      this.$refs['form'].validate((vaild) => {
        if (vaild) {
          this.$emit('confirm', this.dialogType, this.formData)
          this.visible = false
        }
      })
    },
  }
};
</script>

<style lang="scss" scoped>

</style>