<template>
  <div class="transaction">
    <div class="case-title-box">
      <div class="case-title">
        <img class="case-title_icon" src="@/assets/images/icon_hsfl_.png" alt />
        <div class="case-title_max">用户成交价跌幅规则</div>
      </div>
      <div>
        <el-button icon="el-icon-plus" type="primary" size="small" @click="$refs['transactionRuleDia'].open()">添加规则</el-button>
      </div>
    </div>
    <el-table border :data="list">
      <el-table-column type="index" width="100" align="center" label="序号">
      </el-table-column>
      <el-table-column
        align="center"
        label="用户成交价区间"
        prop="region"
      >
        <template slot-scope="{ row }">
          <span>{{ row.lower }}≤区间＜{{ row.upper }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="下跌比例"
        prop="downRatio"
      >
        <template slot-scope="{ row }">
          <span>{{ row.downRatio }}%</span>
        </template>
      </el-table-column>
      <el-table-column label="下跌上限" prop="downLimit" align="center">
      </el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button
            size="small"
            @click="$refs['transactionRuleDia'].open(scope.row)"
            type="primary"
            >编辑</el-button
          >
          <el-button
            size="small"
            @click="delItem(scope.$index)"
            type="danger"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button type="primary" style="margin-top:30px" @click="command = '';usedDialogVisible = true">保存</el-button>

    <TransactionRuleDia ref="transactionRuleDia" @confirm="ruleChangeHandler" />
    <el-dialog title="提示" :visible.sync="usedDialogVisible" :close-on-click-modal="false" width="500px">
      <div style="font-size: 16px; color: #666666; line-height: 50px;">
        <div style="text-align: center;">是否确认保存用户成交价跌幅设置？</div>
      </div>
      <div class="command">
        <div style="width: 88px;">动态口令：</div>
        <el-input v-model="command" placeholder="请输入谷歌动态口令"></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="usedDialogVisible = false">取消</el-button>
        <el-button type="primary" @click.native="saveconfig">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
import TransactionRuleDia from './dialog/TransactionRuleDia.vue';
export default {
  components: { TransactionRuleDia },
  data() {
    return {
      list: [],
      command: '',
      usedDialogVisible: false
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      _api.getAckDownConfig().then(res => {
        this.list = res.data
      })
    },
    ruleChangeHandler(type, ruleItem) {
      if (type == 'add') {
        this.list.push({ ...ruleItem, _t: Math.random()})
      } else {
        const { id, _t } = ruleItem
        const itemIndex = this.list.findIndex(item => {
          if (id) return item.id === id
          return _t === item._t
        })
        this.$set(this.list, itemIndex, ruleItem)
      }
    },
    delItem(i) {
      this.list.splice(i, 1)
    },
    saveconfig() {
      _api.saveAckConfig({
        command: this.command,
        regionViews: this.list
      }).then(() => {
        this.$message.success('保存成功！')
        this.usedDialogVisible = false
        this.getConfig()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.transaction {
  padding: 0 20px;
}
.case-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.case-title {
  // padding: 0 20px;
  display: flex;
  align-items: center;
  width: 100%;

  .case-title_icon {
    width: 20px;
    height: 20px;
    margin-right: 3px;
  }

  .case-title_max {
    font-size: 18px;
    font-family: FZLanTingHei-B-GBK;
    font-weight: bold;
    color: #333333;
    line-height: 41px;
  }

  .case-title_min {
    margin-left: 5px;
    width: 500px;
    height: 13px;
    font-size: 12px;
    font-family: FZLanTingHei-M-GBK;
    font-weight: 400;
    color: #ff687b;
    line-height: 45px;
  }
}
.command {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.command .el-input {
  width: 80%;
}
</style>